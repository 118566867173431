/**
 * 同行信息实体类和接口
 */
import { ReqPage } from '@/model/common/reqPage'
export class CustomerClass {
  id: string | undefined // "string //主键"
  creditCode: string | undefined // 统一社会信用代码
  staffNumRange: string | undefined // 人员规模
  fromTime: string | undefined // 经营开始时间
  type: number | undefined //  法人类型 1 人 2 公司
  bondName: string | undefined // 股票名
  isMicroEnt: number | undefined //  是否是小微企业  	0不是 1是
  usedBondName: string | undefined //  	股票曾用名
  regNumber: string | undefined //  注册号
  percentileScore: string | undefined //  企业评分
  regCapital: string | undefined | number //  注册资本
  name: string | undefined // 企业名
  regInstitute: string | undefined //  登记机关
  regLocation: string | undefined //注册地址
  industry: string | undefined // 行业
  approvedTime: string | undefined // 核准时间
  socialStaffNum: string | undefined // 参保人数
  tags: string | undefined // 企业标签
  taxNumber: string | undefined // 纳税人识别号
  businessScope: string | undefined // 经营范围
  property3: string | undefined // 	英文名
  alias: string | undefined //简称
  orgNumber: string | undefined // 组织机构代码
  regStatus: string | undefined // 企业状态
  estiblishTime: string | undefined | number // 	成立日期
  updateTimes: string | undefined // 更新时间
  bondType: string | undefined // 股票类型
  legalPersonName: string | undefined // 法人
  toTime: string | undefined // 经营结束时间
  actualCapital: string | undefined // 实收注册资金
  companyOrgType: string | undefined // 企业类型
  compForm: number | undefined // 组成形式 1-个人经营、2-家庭经营
  base: string | undefined // 省份简称
  historyNames: string | undefined //  曾用名
  bondNum: string | undefined // 股票号
  regCapitalCurrency: string | undefined // 注册资本币种
  actualCapitalCurrency: string | undefined //  实收注册资本币种
  revokeDate: string | undefined // 吊销日期
  revokeReason: string | undefined // 吊销原因
  cancelDate: string | undefined // 注销日期
  cancelReason: string | undefined // 注销原因
  city: string | undefined // 市
  district: string | undefined // 区
  primaryIndustry: string | undefined // 一级行业
  secondaryIndustry: string | undefined // 二级行业
  thirdIndustry: string | undefined // 三级行业
  internalPrimaryIndustry: string | undefined // 内部一级行业
  internalSecondaryIndustry: string | undefined // 内部二级行业
  isMarket: number | undefined //是否上市 	0：未上市 1：上市 2：IPO
  judicialCase: string | undefined // 司法案件数量
  patent: string | undefined // 专利数量
  isDeleted: number | undefined //是否删除 	0：未删除 1：已删除
  createdBy: string | undefined // 创建人
  createdTime: string | undefined //创建时间
  updatedBy: string | undefined // 更新人
  updatedTime: string | undefined // 	更新时间
  logoUrl: string | undefined // 企业logoURL
  isFollow: 0 | 1 | undefined // 0：未关注  1：关注
  ipoStatus: string | undefined // ipo状态
  saleMan: string | undefined //跟单员
  saleManCode: string | undefined //跟单员工号
  grade: string | undefined
  stockType: string | undefined
  listedDate: string | undefined
}
export class ReqCustomerClass extends ReqPage {
  data: CustomerClass
  constructor() {
    super()
    this.data = new CustomerClass()
  }
}
export interface RegCustomerList extends ReqPage {
  data: CustomerClass[]
}
//客户详情
export interface CustomerDetail {
  id: string | undefined // "string //主键"
  parentId: string | undefined // 关联公司id
  showYear: string | undefined // 年份
  crfgsasrToRevenue: string | undefined // 销售现金流/营业收入
  npAtsopcNrgalYoy: number | undefined //  扣非净利润同比增长(%)
  assetLiabRatio: string | undefined // 资产负债率(%)
  revenueYoy: number | undefined //  营业总收入同比增长(%)
  netProfitAtsopcYoy: string | undefined //  	归属净利润同比增长(%)
  fullyDltRoe: string | undefined //  摊薄净资产收益率(%)
  taxRate: string | undefined //  实际税率(%)
  receivableTurnoverDays: string | undefined //  应收账款周转天数(天)
  preReceivable: string | undefined // 预收款/营业收入
  currentRatio: string | undefined //  流动比率
  operateCashFlowPs: string | undefined //每股经营现金流(元)
  grossSellingRate: string | undefined // 毛利率(%)
  currentLiabToTotalLiab: string | undefined // 流动负债/总负债(%)
  quickRatio: string | undefined // 速动比率
  netInterestOfTotalAssets: string | undefined // 摊薄总资产收益率(%)
  operatingTotalRevenueLrrSq: string | undefined // 营业总收入滚动环比增长(%)
  profitDeductNrgalLrrSq: string | undefined // 扣非净利润滚动环比增长(%)
  wgtAvgRoe: string | undefined // 	加权净资产收益率(%)
  basicEps: string | undefined //基本每股收益(元)
  netSellingRate: string | undefined // 净利率(%)
  totalCapitalTurnover: string | undefined // 总资产周转率(次)
  netProfitAtsopcLrrSq: string | undefined // 	归属净利润滚动环比增长(%)
  netProfitPerShare: string | undefined // 每股净资产(元)
  capitalReserve: string | undefined // 每股公积金(元)
  profitNrgalSq: string | undefined // 扣非净利润(元)
  inventoryTurnoverDays: string | undefined // 存货周转天数(天)
  totalRevenue: string | undefined // 营业总收入(元)
  undistriProfitPs: string | undefined // 每股未分配利润(元)
  dltEarningsPerShare: number | undefined // 稀释每股收益(元)
  netProfitAtsopc: string | undefined // 归属净利润(元)
  basicEPsNetOfNrgal: string | undefined //  扣非每股收益(元)
  opToRevenue: string | undefined // 毛利润（元）
  ncfFromOaToRevenue: string | undefined // 经营现金流/营业收入
  isDeleted: number | undefined //  是否删除;0：未删除 1：已删除
  createdBy: string | undefined // 创建人
  createdTime: string | undefined // 创建时间
  updatedBy: string | undefined // 更新人
  updatedTime: string | undefined // 更新时间
  [key: string]: string | undefined | number
}
export interface GetCustomerDetail {
  bcicCustomerInfoVO: CustomerClass
  bcicCustomerDetailVOList: CustomerDetail[]
}
