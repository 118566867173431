
import { defineComponent, toRefs, ref, computed, onMounted, watch, reactive, nextTick } from 'vue'
import listLayout from '@/components/listLayout/listLayout.vue'
import FormItem from '@/components/formItem/index.vue'
import { CustomerClass, ReqCustomerClass } from '@/model/customerManagement/customer'
import { message } from 'ant-design-vue'
import { useRouter, onBeforeRouteUpdate, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import yTable from '@/components/yTable/index.vue'
import Data from '@/model/common/Data'
import customerManagementApi from '@/axios/api/customerManagement'
import editButton from '@/components/editButton/index.vue'
import AchieveApi from '@/axios/api/achievementApi'
import { deleteModal } from '@/utils/deleteModal'
import { FormItemListType } from '@/types/comm'
import Drawer from '@/components/Drawer/Drawer.vue'
import { ColumnsTypeData } from '@/components/Drawer/Drawer'
export default defineComponent({
  components: {
    listLayout,
    FormItem,
    editButton,
    yTable,
    Drawer,
  },
  setup() {
    /**
     * @description: 获取筛选后可展示表头列表
     * @param { Array<ColumnsTypeData> } data 当前所有表头列表
     * @return {*}
     */
    const updateColumns = (value: Array<ColumnsTypeData>) => {
      pageData.loading = true
      pageData.columns = value
      nextTick(() => {
        pageData.loading = false
      })
    }
    const peerTable = ref()
    //获取路由
    let router = useRouter()
    let route = useRoute()
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj
    const pageData = reactive({
      isShowDrawer: false, //列设置弹框展示控制
      selectFrom: new ReqCustomerClass(),
      dataSource: [] as CustomerClass[],
      columns: [
        {
          title: '公司名称',
          dataIndex: 'name',
          width: 150,
          key: 'name',
          resizable: true,
        },
        // {
        //   title: '所属行业',
        //   dataIndex: 'industry',
        //   width: 150,
        //   key: 'industry',
        //   resizable: true,
        // },
        // {
        //   title: '是否上市',
        //   dataIndex: 'isMarket',
        //   width: 150,
        //   key: 'isMarket',
        //   resizable: true,
        // },
        // {
        //   title: 'IPO状态',
        //   dataIndex: 'ipoStatus',
        //   width: 130,
        //   key: 'ipoStatus',
        // },
        // {
        //   title: '上市板块',
        //   dataIndex: 'bondType',
        //   width: 150,
        //   key: 'bondType',
        //   resizable: true,
        // },
        {
          title: '注册资本',
          dataIndex: 'regCapital',
          width: 150,
          key: 'regCapital',
          resizable: true,
        },
        {
          title: '所属内部行业',
          dataIndex: 'internal',
          width: 150,
          key: 'internal',
          resizable: true,
        },
        {
          title: '主营业务',
          dataIndex: 'base',
          width: 100,
          key: 'base',
          resizable: true,
        },
        {
          title: '所属地区',
          dataIndex: 'base',
          width: 100,
          key: 'base',
          resizable: true,
        },
        {
          title: '注册时间',
          dataIndex: 'estiblishTime',
          width: 100,
          key: 'estiblishTime',
          resizable: true,
        },
        // {
        //   title: '图片',
        //   dataIndex: 'logoUrl',
        //   width: 80,
        //   key: 'logoUrl',
        //   resizable: true,
        // },
        // {
        //   title: '关注状态',
        //   dataIndex: 'isFollow',
        //   width: 90,
        //   key: 'isFollow',
        //   resizable: true,
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 120,
          key: 'operation',
          fixed: 'right',
          resizable: true,
        },
      ] as ColumnsTypeData[],
      selectedRowKeys: [] as string[],
      loading: false,
      formItemList: [
        {
          type: 'subInput',
          prop: 'customerName',
          placeholder: '请输入公司名称',
          label: '公司名称',
        },
        // {
        //   type: 'slot',
        //   prop: 'industry',
        //   placeholder: '请输入所属行业',
        //   label: '所属行业:',
        // },
        {
          type: 'subSelect',
          prop: 'internalPrimaryIndustry',
          placeholder: '请选择内部一级行业',
          label: '内部一级行业:',
          optionList: [] as Data[],
        },
        {
          type: 'subSelect',
          prop: 'internalSecondaryIndustry',
          placeholder: '请选择内部二级行业',
          label: '内部二级行业:',
          optionList: [] as Data[],
        },
        {
          type: 'subSelect',
          prop: 'base',
          placeholder: '请输入所属地区',
          label: '所属地区:',
          optionList: [
            {
              ProID: 1,
              value: '北京市',
              ProSort: 1,
              ProRemark: '直辖市',
            },
            {
              ProID: 2,
              value: '天津市',
              ProSort: 2,
              ProRemark: '直辖市',
            },
            {
              ProID: 3,
              value: '河北省',
              ProSort: 5,
              ProRemark: '省份',
            },
            {
              ProID: 4,
              value: '山西省',
              ProSort: 6,
              ProRemark: '省份',
            },
            {
              ProID: 5,
              value: '内蒙古自治区',
              ProSort: 32,
              ProRemark: '自治区',
            },
            {
              ProID: 6,
              value: '辽宁省',
              ProSort: 8,
              ProRemark: '省份',
            },
            {
              ProID: 7,
              value: '吉林省',
              ProSort: 9,
              ProRemark: '省份',
            },
            {
              ProID: 8,
              value: '黑龙江省',
              ProSort: 10,
              ProRemark: '省份',
            },
            {
              ProID: 9,
              value: '上海市',
              ProSort: 3,
              ProRemark: '直辖市',
            },
            {
              ProID: 10,
              value: '江苏省',
              ProSort: 11,
              ProRemark: '省份',
            },
            {
              ProID: 11,
              value: '浙江省',
              ProSort: 12,
              ProRemark: '省份',
            },
            {
              ProID: 12,
              value: '安徽省',
              ProSort: 13,
              ProRemark: '省份',
            },
            {
              ProID: 13,
              value: '福建省',
              ProSort: 14,
              ProRemark: '省份',
            },
            {
              ProID: 14,
              value: '江西省',
              ProSort: 15,
              ProRemark: '省份',
            },
            {
              ProID: 15,
              value: '山东省',
              ProSort: 16,
              ProRemark: '省份',
            },
            {
              ProID: 16,
              value: '河南省',
              ProSort: 17,
              ProRemark: '省份',
            },
            {
              ProID: 17,
              value: '湖北省',
              ProSort: 18,
              ProRemark: '省份',
            },
            {
              ProID: 18,
              value: '湖南省',
              ProSort: 19,
              ProRemark: '省份',
            },
            {
              ProID: 19,
              value: '广东省',
              ProSort: 20,
              ProRemark: '省份',
            },
            {
              ProID: 20,
              value: '海南省',
              ProSort: 24,
              ProRemark: '省份',
            },
            {
              ProID: 21,
              value: '广西壮族自治区',
              ProSort: 28,
              ProRemark: '自治区',
            },
            {
              ProID: 22,
              value: '甘肃省',
              ProSort: 21,
              ProRemark: '省份',
            },
            {
              ProID: 23,
              value: '陕西省',
              ProSort: 27,
              ProRemark: '省份',
            },
            {
              ProID: 24,
              value: '新 疆维吾尔自治区',
              ProSort: 31,
              ProRemark: '自治区',
            },
            {
              ProID: 25,
              value: '青海省',
              ProSort: 26,
              ProRemark: '省份',
            },
            {
              ProID: 26,
              value: '宁夏回族自治区',
              ProSort: 30,
              ProRemark: '自治区',
            },
            {
              ProID: 27,
              value: '重庆市',
              ProSort: 4,
              ProRemark: '直辖市',
            },
            {
              ProID: 28,
              value: '四川省',
              ProSort: 22,
              ProRemark: '省份',
            },
            {
              ProID: 29,
              value: '贵州省',
              ProSort: 23,
              ProRemark: '省份',
            },
            {
              ProID: 30,
              value: '云南省',
              ProSort: 25,
              ProRemark: '省份',
            },
            {
              ProID: 31,
              value: '西藏自治区',
              ProSort: 29,
              ProRemark: '自治区',
            },
            {
              ProID: 32,
              value: '台湾省',
              ProSort: 7,
              ProRemark: '省份',
            },
            {
              ProID: 33,
              value: '澳门特别行政区',
              ProSort: 33,
              ProRemark: '特别行政区',
            },
            {
              ProID: 34,
              value: '香港特别行政区',
              ProSort: 34,
              ProRemark: '特别行政区',
            },
          ],
        },
        // {
        //   type: 'subSelect',
        //   prop: 'isMarket',
        //   placeholder: '请输入是否上市',
        //   label: '是否上市:',
        //   optionList: [
        //     {
        //       value: 0,
        //       label: '未上市',
        //     },
        //     {
        //       value: 1,
        //       label: '上市',
        //     },
        //     {
        //       value: 2,
        //       label: 'IPO',
        //     },
        //   ],
        // },
        {
          type: 'subSelect',
          prop: 'isFollow',
          placeholder: '请输入是否关注',
          label: '是否关注:',
          optionList: [
            {
              value: 0,
              label: '未关注',
            },
            {
              value: 1,
              label: '关注',
            },
          ],
        },
      ] as FormItemListType<CustomerClass>,
      primaryIndustry: [],
      options: [] as Data[],
      customerAddvisible: false,
      customerAddName: '',
    })
    onBeforeRouteUpdate((to, from) => {
      console.log(from.name)
      store.commit('setRegForm', [from.name, pageData.selectFrom])
    })
    // //如果有缓存的查询数据 则采用缓存的数据
    if (store.state.regForm[route.name as string] !== undefined) {
      pageData.selectFrom = JSON.parse(JSON.stringify(store.state.regForm[route.name as string]))
    }
    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.selectFrom.pageSize,
        currentPage: pageData.selectFrom.currentPage,
        total: pageData.selectFrom.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.selectFrom.pageSize = pageSize
          pageData.selectFrom.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.selectFrom.pageSize = size
          pageData.selectFrom.currentPage = current
          initeList()
        },
      }
      return page
    })
    //获取表格信息
    const initeList = () => {
      pageData.loading = true
      pageData.selectFrom.data.isMarket = 2
      customerManagementApi
        .GetCustomerList(pageData.selectFrom)
        .then((res) => {
          pageData.dataSource = []
          if (res.data) {
            Object.assign(pageData.dataSource, res.data)
          }
          pageData.selectFrom.currentPage = res.currentPage as number
          pageData.selectFrom.pageSize = res.pageSize
          pageData.selectFrom.dataCount = res.dataCount
          pageData.loading = false
        })
        .catch((err) => {
          pageData.loading = false
          message.error(err)
        })
    }
    //重置数据
    const selectList = () => {
      pageData.selectFrom.currentPage = 1
      pageData.selectFrom.pageSize = 10
      initeList()
    }
    //渲染后
    onMounted(() => {
      console.log(document.querySelector('.ant-cascader-menus'))
    })
    //查询数据
    initeList()
    //新增按钮
    const detailData = (id: string) => {
      router.push(`/customerManagement/customerDetail/${id}`)
    }
    //编辑按钮
    const updateData = () => {
      message.warning('开发中')
    }
    //跳转至账号设置页面
    const accountSet = (id: string) => {
      console.log(`跳转至id为${id}页面`)
      router.push(`/competitiveAnalysis/peerData/accountInformation/${id}`)
    }
    //搜索
    const search = () => {
      console.log(pageData.selectFrom)
    }
    //
    const reset = () => {
      pageData.selectFrom.data = new CustomerClass()
      pageData.primaryIndustry.length = 0
      selectList()
    }
    //获取外部行业
    customerManagementApi
      .GetCustomerIndustry({ data: { parentId: '0' }, noLoading: true })
      .then((res) => {
        console.log(res.data)
        if (res.data) {
          pageData.options = (res.data as Data[]).map((item) => {
            return {
              label: item.industryName,
              value: item.industryName,
              custom: item.id,
              isLeaf: false,
            }
          })
        }
      })
      .catch((err) => {
        message.error(err)
      })
    const loadData = (val: Data[]) => {
      val[val.length - 1].isLeaf = true
      customerManagementApi
        .GetCustomerIndustry({ data: { parentId: val[val.length - 1].custom }, noLoading: true })
        .then((res) => {
          if (res.data && (res.data as Data[]).length !== 0) {
            val[val.length - 1].children = (res.data as Data[]).map((item) => {
              return {
                label: item.industryName,
                value: item.industryName,
                custom: item.id,
                isLeaf: val.length > 1 ? true : false,
              }
            })
            console.log(val)
          }
          if (val[val.length - 1].children) {
            val[val.length - 1].isLeaf = false
          }
        })
        .catch((err) => {
          val[0].isLeaf = false
          message.error(err)
        })
    }
    const follow = (customer: CustomerClass) => {
      if (customer.isFollow === 1) {
        customerManagementApi
          .CustomerFollowCancel({ customerId: customer.id })
          .then(() => {
            message.success('取消关注成功')
            customer.isFollow = 0
          })
          .catch((err) => {
            message.error(err)
          })
      } else {
        customerManagementApi
          .CustomerFollowAdd({ customerId: customer.id })
          .then(() => {
            message.success('关注成功')
            customer.isFollow = 1
          })
          .catch((err) => {
            message.error(err)
          })
      }
    }
    //行业改变事件
    const cascaderChange = (value: string[]) => {
      pageData.selectFrom.data.primaryIndustry = value[0]
      pageData.selectFrom.data.secondaryIndustry = value[1]
      pageData.selectFrom.data.thirdIndustry = value[2]
    }
    //下拉加载更多
    const selectLoadMore = () => {
      console.log(123)
    }
    //新增客户
    const handleOk = () => {
      if (!pageData.customerAddName) {
        message.warning('请输入要添加的客户名称')
        return
      }
      customerManagementApi
        .AddCustomer(pageData.customerAddName, 'IPO')
        .then(() => {
          message.success('新增成功')
          pageData.customerAddvisible = false
          initeList()
        })
        .catch((err) => {
          message.error(err)
        })
    }
    const firstOptionList = pageData.formItemList.find(
      (item) => item.prop === 'internalPrimaryIndustry'
    )
    const secOptionList = pageData.formItemList.find(
      (item) => item.prop === 'internalSecondaryIndustry'
    )
    // 内部一级行业获取
    AchieveApi.queryOptionalType({ optionalType: 'primaryIndustry' })
      .then((res) => {
        if (res) {
          if (firstOptionList) {
            firstOptionList.optionList = res.map((item) => {
              return {
                label: item.name as string,
                value: item.name as string,
                custom: item.id,
              }
            })
          }
        }
      })
      .catch((err) => {
        message.error(err)
      })
    watch(
      () => pageData.selectFrom.data.internalPrimaryIndustry,
      (newVal) => {
        if (secOptionList) {
          secOptionList.optionList = []
        }
        pageData.selectFrom.data.internalSecondaryIndustry = undefined
        if (newVal) {
          AchieveApi.queryOptionalType({
            optionalType: 'secondaryIndustry',
            subParameter:
              firstOptionList &&
              firstOptionList.optionList?.find((item) => item.value === newVal)?.custom,
            noLoading: true,
          })
            .then((res) => {
              if (res && secOptionList) {
                secOptionList.optionList = res.map((item) => {
                  return {
                    label: item.name as string,
                    value: item.name as string,
                    custom: item.value,
                  }
                })
              }
            })
            .catch((err) => {
              message.error(err)
            })
        }
      }
    )

    // 全选事件
    const checkedChange = (checked: boolean) => {
      pageData.selectedRowKeys = checked
        ? (pageData.dataSource.map((item) => item.id) as string[]) || []
        : []
    }
    // 批量导出
    const exportFile = (id?: string) => {
      console.log(id)

      // analysisOfPriceApi
      //   .analysisOfPriceApiExport(id ? [id] : pageData.selectedRowKeys)
      //   .then((res) => {
      //     console.log(res)
      //     let blob = new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' })
      //     let url = window.URL.createObjectURL(blob)
      //     let a = document.createElement('a')
      //     a.download = ''
      //     a.href = url
      //     a.click()
      //     a.remove()
      //     message.success('导出成功')
      //   })
      //   .catch((err) => {
      //     message.error(err)
      //   })
    }
    // 导入函数
    const importFile = (param: string) => {
      console.log(param)
      // const formData = new FormData()
      // formData.append('file', param.file as Blob)
      // analysisOfPriceApi
      //   .analysisOfPriceApiImport(formData)
      //   .then(() => {
      //     message.success('导入成功')
      //     initeList()
      //   })
      //   .catch((err) => {
      //     message.error(err)
      //   })
    }

    // 扭转上市
    const turnMarket = () => {
      customerManagementApi
        .TurnMarket(pageData.selectedRowKeys[0])
        .then(() => {
          message.success('成功')
          initeList()
        })
        .catch((err) => {
          message.error(err)
        })
    }

    // 删除
    const delList = () => {
      const ok = () => {
        customerManagementApi
          .DeleteCustomerInfo(pageData.selectedRowKeys)
          .then(() => {
            message.success('删除成功')
            pageData.selectedRowKeys = []
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      }
      const onCancel = () => {
        console.log('Cancel')
      }
      deleteModal(ok, onCancel)
    }
    return {
      delList,
      turnMarket,
      importFile,
      exportFile,
      checkedChange,
      detailData,
      ...toRefs(pageData),
      peerTable,
      accountSet,
      updateData,
      search,
      fallbackImg: require('@/assets/img/fallbackImg.svg'),
      selectList,
      // delData,
      reset,
      buttonObj,
      currentConfigure,
      loadData,
      follow,
      cascaderChange,
      selectLoadMore,
      handleOk,
      updateColumns,
    }
  },
})
